import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { ImageService } from '../../services/image.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../services/alert.service';

interface MediaBlob {
  url: string;
  type: string;
  isLoading: boolean;
}

@Component({
  selector: 'blu-media-input',
  templateUrl: './media-input.component.html',
  styleUrls: ['./media-input.component.scss'],
})
export class MediaInputComponent implements OnDestroy {
  @Input() media: string[] = [];
  @Output() mediaChange = new EventEmitter<string[]>();
  @Input() folderPath: string = 'media';
  @Input() readonly = false;

  inputAccept = 'image/png, image/jpeg, audio/*, video/mp4';
  inputText = 'Imagem, áudio ou vídeo';

  #mediaBlob: MediaBlob[] = [];

  constructor(
    public sharedService: SharedService,
    private imageService: ImageService,
    public userService: UserService,
    public analyticsService: AnalyticsService,
    private alertService: AlertService,
  ) {}

  get mediaBlob(): MediaBlob[] {
    if (this.#mediaBlob.length !== this.media.length) {
      this.#mediaBlob = this.media.map((url) => ({
        url,
        type: this.getFileType(url),
        isLoading: false,
      }));
    }
    return this.#mediaBlob;
  }

  getFileType(url: string): string {
    if (
      ['.jpeg', '.png', '.jpg', '.jfif', '.webp'].some((ext) =>
        url.includes(ext),
      )
    ) {
      return 'image';
    }
    if (url.includes('.mp4')) {
      return 'video';
    }
    if (url.includes('.mp3') || url.includes('.ogg')) {
      return 'audio';
    }
    return '';
  }

  async uploadMedia(e: any): Promise<void> {
    const files = Array.from(e?.target?.files || []) as File[];
    const orgId = this.userService.organization()?.uid;

    const mediaCount = this.media.length;
    const uploadPromises = files.map(async (file: File, index) => {
      if (file.size > 20971520) {
        this.alertService.alert({
          title: 'Arquivo muito grande',
          message:
            'O arquivo deve ter no máximo 20MB. Envie-o pelo whatsapp e baixe-o novamente para reduzir o tamanho.',
        });
        return;
      }

      this.media[index + mediaCount] = '';
      this.#mediaBlob[index + mediaCount] = {
        url: URL.createObjectURL(file),
        type: file.type,
        isLoading: true,
      };
      const extension = file.name.split('.').pop();
      const timestamp = new Date().getTime().toString();
      const randomStr = Math.random().toString(36).substring(2, 10);

      let mediaBlob: Blob | File | null = file;

      if (mediaBlob?.type.startsWith('image/')) {
        mediaBlob = await this.imageService.resizeImage(file);
      }
      if (!mediaBlob) return;

      const url = await this.imageService.uploadBlobToFirebase(
        `${orgId}_${timestamp}_${randomStr}.${extension}`,
        mediaBlob,
        this.folderPath,
      );
      this.#mediaBlob[index + mediaCount].isLoading = false;
      this.media[index + mediaCount] = url;
      this.mediaChange.emit(this.media);
    });

    await Promise.allSettled(uploadPromises);
  }

  removeMedia(blob: MediaBlob) {
    const index = this.#mediaBlob.indexOf(blob);
    if (index === -1) return;
    this.#mediaBlob.splice(index, 1);
    this.media.splice(index, 1);
    this.mediaChange.emit(this.media);
  }

  isImage(mediaBlob: MediaBlob) {
    return !mediaBlob.isLoading && mediaBlob.type.startsWith('image');
  }

  isVideo(mediaBlob: MediaBlob) {
    return !mediaBlob.isLoading && mediaBlob.type.startsWith('video');
  }

  isAudio(mediaBlob: MediaBlob) {
    return !mediaBlob.isLoading && mediaBlob.type.startsWith('audio');
  }

  ngOnDestroy(): void {
    for (const blob of this.mediaBlob) {
      URL.revokeObjectURL(blob.url);
    }
  }
}
