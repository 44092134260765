import { inject, Injectable } from '@angular/core';
import { ImageService } from './image.service';

@Injectable({ providedIn: 'root' })
export class MediaInputService {
  imageService = inject(ImageService);

  uploadFiles(orgId: string, folder: string, files: File[]) {
    return files.map(async (file: File) =>
      this.uploadFile(orgId, folder, file),
    );
  }

  deleteFile(url: string) {
    return this.imageService.deleteFileFromFirebase(url);
  }

  async uploadFile(orgId: string, folder: string, file: File) {
    if (file.size > 20971520) {
      throw new Error(
        'O arquivo deve ter no máximo 20MB. Envie-o pelo whatsapp e baixe-o novamente para reduzir o tamanho.',
      );
    }

    const extension = file.name.split('.').pop();
    const timestamp = new Date().getTime().toString();
    const randomStr = Math.random().toString(36).substring(2, 10);

    let mediaBlob = file as Blob;
    if (mediaBlob.type.startsWith('image/')) {
      mediaBlob = await this.imageService.resizeImage(file);
    }

    const url = await this.imageService.uploadBlobToFirebase(
      `${orgId}_${timestamp}_${randomStr}.${extension}`,
      mediaBlob,
      folder,
    );
    return url;
  }

  getFileType(url: string): string {
    if (
      ['.jpeg', '.png', '.jpg', '.jfif', '.webp'].some((ext) =>
        url.includes(ext),
      )
    ) {
      return 'image';
    }
    if (url.includes('.mp4')) {
      return 'video';
    }
    if (url.includes('.mp3') || url.includes('.ogg')) {
      return 'audio';
    }
    return 'unknown';
  }
}
