<div class="card">
  <!-- header -->
  <h2>
    <kor-icon icon="phonelink_ring" class="font-normal"></kor-icon>
    Canais ({{ channelsService.channels.length }}/{{
      userService.getChannelLimit()
    }})
    <kor-button
      class="ml-auto"
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [disabled]="
        channelsService.channels.length >= userService.getChannelLimit()
      "
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
    ></kor-button>
  </h2>
  <!-- error -->
  @if (channelsService.channels.length >= userService.getChannelLimit()) {
    <p class="error">
      <b
        >O limite de canais para seu plano ({{ userService.getChannelLimit() }})
        foi atingido.</b
      >
      <a
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+contratar+mais+canais"
      >
        <kor-button label="Adquirir mais canais"></kor-button>
      </a>
    </p>
  }

  <p class="text-balance">
    Crie canais de atendimento e os ative como uma sessão do WhatsApp Web. Ative
    chatbots a um canal para habilitar respostas automáticas.
  </p>

  <!-- body -->
  @if (channelsService.channels.length > 0) {
    <kor-table columns="2fr 2fr 1fr 1fr 1fr">
      <kor-table-row slot="header">
        <kor-table-cell head>Nome</kor-table-cell>
        <kor-table-cell head>Número ativo</kor-table-cell>
        <kor-table-cell head>Chatbot ativo</kor-table-cell>
        <kor-table-cell head>Canal ativo</kor-table-cell>
        <kor-table-cell head></kor-table-cell>
      </kor-table-row>
      @for (channel of channelsService.channels; track channel) {
        <kor-table-row>
          <kor-table-cell class="flex items-center">
            <b>{{ channel.name }}</b>
          </kor-table-cell>
          <kor-table-cell>{{ channel.numbers[0] || '–' }} </kor-table-cell>
          <kor-table-cell
            (click)="$event.stopPropagation()"
            [routerLink]="[
              { outlets: { modal: ['select-flow', channel.uid] } }
            ]"
          >
            @if (channel.activeFlow) {
              <kor-button color="secondary" class="px-3">
                <kor-icon class="block" icon="cached"></kor-icon>
                Alterar
              </kor-button>
            } @else {
              <kor-button
                color="secondary"
                icon="account_tree"
                label="Escolher"
              ></kor-button>
            }
          </kor-table-cell>
          <kor-table-cell>
            @if (channel.status === 'CONNECTED') {
              <kor-icon
                class="block"
                icon="check_circle"
                color="rgb(var(--functional-green))"
              ></kor-icon>
            } @else {
              <kor-button
                color="secondary"
                class="px-3"
                (click)="$event.stopPropagation()"
                [routerLink]="[
                  { outlets: { modal: ['activate', channel.uid] } }
                ]"
              >
                <kor-icon class="block font-normal" icon="qr_code"></kor-icon>
                Ativar
              </kor-button>
            }
          </kor-table-cell>
          <kor-table-cell class="justify-end">
            <kor-button
              color="secondary"
              icon="edit"
              label="Editar"
              [routerLink]="[{ outlets: { modal: ['add', channel.uid] } }]"
            ></kor-button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <kor-empty-state
      class="min-h-80"
      icon="phonelink_ring"
      label="Nenhum canal conectado"
    ></kor-empty-state>
  }
</div>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
