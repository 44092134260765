<div class="card">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <kor-icon icon="account_tree" class="font-normal"></kor-icon>
    Chatbots
    <kor-button
      class="ml-auto"
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
    ></kor-button>
  </h2>

  <p class="text-balance">
    Crie chatbots e os conecte a canais para realizar respostas automáticas. Um
    chatbot pode ser conectado a um ou mais canais.
  </p>

  <!-- body -->
  @if (flowsService.flows.length > 0) {
    <kor-table columns="1fr auto auto">
      <kor-table-row slot="header">
        <kor-table-cell head>Nome</kor-table-cell>
        <kor-table-cell head></kor-table-cell>
        <kor-table-cell head></kor-table-cell>
      </kor-table-row>
      @for (flow of flowsService.flows; track flow) {
        <kor-table-row>
          <kor-table-cell>
            <b>{{ flow.label }}</b>
          </kor-table-cell>
          <kor-table-cell>
            <kor-button
              button
              color="secondary"
              icon="account_tree"
              label="Modificar fluxo"
              (click)="$event.stopPropagation()"
              [routerLink]="flow.uid"
            ></kor-button>
          </kor-table-cell>
          <kor-table-cell>
            <kor-button
              button
              color="secondary"
              icon="edit"
              label="Editar"
              (click)="$event.stopPropagation()"
              [routerLink]="[{ outlets: { modal: ['add', flow.uid] } }]"
            ></kor-button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <kor-empty-state
      class="min-h-80"
      icon="account_tree"
      label="Nenhum chatbot criado"
    ></kor-empty-state>
  }
</div>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
