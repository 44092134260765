import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'blu-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  constructor(public router: Router) {}
}
