<div class="card">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <kor-icon icon="support_agent" class="font-normal"></kor-icon>
    Atendentes ({{ operatorsService.operators.length }}/{{
      userService.getOperatorLimit()
    }})
    <kor-button
      class="ml-auto"
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [disabled]="
        operatorsService.operators.length >= userService.getOperatorLimit()
      "
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
    ></kor-button>
  </h2>
  <!-- error -->
  @if (operatorsService.operators.length >= userService.getOperatorLimit()) {
    <p class="error">
      <b
        >O limite de atendentes para seu plano ({{
          userService.getOperatorLimit()
        }}) foi atingido.</b
      >
      <a
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+contratar+mais+atendentes"
      >
        <kor-button label="Adquirir mais atendentes"></kor-button>
      </a>
    </p>
  }

  <p class="text-balance">
    Atendentes podem ser associados a canais para ter acesso às mensagens pelo
    <a href="https://chat.blu.direct/" class="font-bold text-accent-600"
      >painel de atendimento</a
    >
    utilizando o e-mail e senha cadastrados.
  </p>

  <!-- body -->

  @if (operatorsService.operators.length > 0) {
    <kor-table>
      <kor-table-row slot="header">
        <kor-table-cell head grid-cols="10">Nome</kor-table-cell>
        <kor-table-cell head grid-cols="10">Email</kor-table-cell>
        <kor-table-cell grid-cols="4"></kor-table-cell>
      </kor-table-row>
      @for (operator of operatorsService.operators; track operator) {
        <kor-table-row>
          <kor-table-cell grid-cols="10">
            <b>{{ operator.name }}</b>
          </kor-table-cell>
          <kor-table-cell grid-cols="10">{{ operator.email }} </kor-table-cell>
          <kor-table-cell grid-cols="4" alignment="right">
            <kor-button
              button
              label="Editar"
              icon="edit"
              color="secondary"
              [routerLink]="[{ outlets: { modal: ['add', operator.uid] } }]"
            ></kor-button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <kor-empty-state
      class="min-h-80"
      icon="support_agent"
      label="Nenhum atendente registrado"
    ></kor-empty-state>
  }
</div>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
