<div class="card">
  <h2>
    <kor-icon icon="lock_open"></kor-icon>
    Chaves de acesso ({{ users.length }}/{{ getUserLimit() }})
    <kor-button
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [routerLink]="[{ outlets: { modal: ['add-user', ''] } }]"
      [disabled]="users.length >= getUserLimit()"
      (click)="analyticsService.logEvent('admin_add_user_button_click')"
    ></kor-button>
  </h2>
  <!-- error -->
  @if (users.length >= getUserLimit()) {
    <p class="error">
      <b
        >O limite de chaves de acesso para seu plano ({{ getUserLimit() }}) foi
        atingido.</b
      >
      <a
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+mais+chaves+de+acesso"
      >
        <kor-button label="Adquirir +10 acessos"></kor-button>
      </a>
    </p>
  }
  <!-- tags -->
  <div class="tags">
    <kor-tag
      class="create-tag"
      label="Criar categoria"
      icon="add"
      [routerLink]="[{ outlets: { modal: ['add-user-tag'] } }]"
      (click)="analyticsService.logEvent('admin_add-user-tag_button_click')"
    ></kor-tag>

    @for (tag of userService.organization()?.userTags; track tag) {
      <kor-tag [label]="tag" removable (remove)="deleteTag(tag)"></kor-tag>
    }
  </div>
  <!-- list -->
  <kor-table>
    @for (user of users; track user) {
      <kor-table-row
        [routerLink]="[{ outlets: { modal: ['add-user', user.uid] } }]"
        (click)="analyticsService.logEvent('admin_user_row_click')"
      >
        <kor-table-cell class="name">
          <b
            >{{ user.name }}
            @for (tag of user.tags; track tag) {
              <kor-tag [label]="tag"></kor-tag>
            }
          </b>
        </kor-table-cell>
        <kor-table-cell>{{
          user?.lastCampaignDate?.toDate() | date: 'MMM d, HH:mm'
        }}</kor-table-cell>
      </kor-table-row>
    }
  </kor-table>
</div>

<router-outlet name="modal"></router-outlet>
