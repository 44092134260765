<a href="https://home.blu.direct/" class="no-underline text-inherit">
  <h1 class="flex items-center gap-1">
    <img src="assets/favicon.png" height="48" width="48" />blü
  </h1>
</a>
<blu-announcement></blu-announcement>

<!-- card -->
<div class="card w-80 h-[480px]">
  <router-outlet></router-outlet>
</div>

2.8.1
