import { Injectable, signal } from '@angular/core';
import {
  onSnapshot,
  orderBy,
  QueryDocumentSnapshot,
  serverTimestamp,
  where,
} from '@angular/fire/firestore';
import {
  FirestoreCollections,
  IBluUser,
  IOnboarding,
  IOrganization,
  IUser,
  OnboardingStepKey,
  SubscriptionType,
} from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user = signal<IBluUser | null | undefined>(undefined);
  organization = signal<IOrganization | null | undefined>(undefined);

  userCache = new Map<string, IBluUser>();

  number: string | undefined;
  isCreatingUser = false;

  constructor(
    private firestore: SharedFirestoreService,
    private alertService: AlertService,
  ) {}

  async initializeOrg(orgId: string) {
    const orgData = await this.firestore.getDoc<IOrganization>(
      FirestoreCollections.Organization,
      orgId,
    );
    if (!orgData) throw new Error(`Organization not found ${orgId}.`);
    this.organization.set(orgData);
  }

  async getUsersByOrg(orgId: string): Promise<QueryDocumentSnapshot<IUser>[]> {
    const querySnap = await this.firestore.getDocs<IUser>(
      FirestoreCollections.User,
      where('organization', '==', orgId),
      where('deletedAt', '==', null),
      orderBy('lastCampaignDate', 'desc'),
    );
    return querySnap.docs;
  }

  async getUserByNumber(
    number: string,
    onlyActive = false,
  ): Promise<IUser | undefined> {
    const queryConstraints = [where('numbers', 'array-contains', number)];
    if (onlyActive) queryConstraints.push(where('deletedAt', '==', null));
    const userSnap = await this.firestore.getDocs<IUser>(
      FirestoreCollections.User,
      ...queryConstraints,
    );
    return userSnap.docs[0]?.data() as IUser;
  }

  async getUserById(userId: string): Promise<IBluUser | undefined> {
    if (this.userCache.has(userId)) {
      return this.userCache.get(userId);
    }
    const user = await this.firestore.getDoc<IBluUser>(
      FirestoreCollections.User,
      userId,
    );
    if (user) this.userCache.set(userId, user);
    return user;
  }

  isBasic() {
    const plan = this.organization()?.subscription?.plan;
    return (
      plan === SubscriptionType.Plus ||
      plan === SubscriptionType.Pro ||
      plan === SubscriptionType.Basic
    );
  }

  async updateUser(id: string, docData: any): Promise<void> {
    await this.firestore.updateDoc(FirestoreCollections.User, id, docData);
  }

  async createUser(docData: any) {
    if (!this.organization) return;

    // check if number is already registered and if not, create new user
    const user = await this.getUserByNumber(docData.numbers[0], false);

    if (user) {
      if (user.organization !== this.organization()?.uid) {
        this.alertService.alert({
          title: 'Usuário já cadastrado',
          message:
            'O usuário já foi cadastrado em outra organização. \nFale conosco para transferí-lo.',
        });
        return;
      }
      if (!user.deletedAt) {
        this.alertService.alert({
          title: 'Usuário já cadastrado',
          message: 'O usuário já está cadastrado.',
        });
        return;
      }
      // if user document exists, and user is deleted, reactivate it
      // if the deletion date is older than `daysThreshold` days ago
      const daysThreshold = 14;
      const diff = Date.now() - user.deletedAt.toMillis();
      if (diff < daysThreshold * 24 * 60 * 60 * 1000) {
        const days = daysThreshold - Math.floor(diff / (1000 * 60 * 60 * 24));
        this.alertService.alert({
          title: 'Usuário deletado recentemente',
          message: `Usuário deletado recentemente. Aguarde ${days} dias para reativar.`,
        });
        return;
      }
      await this.updateUser(user.uid, {
        deletedAt: null,
        isGuest: false,
      });
      return;
    }

    if (this.isCreatingUser) return;
    this.isCreatingUser = true;

    const createUserData = {
      ...docData,
      lastCampaignDate: null,
      createdAt: serverTimestamp(),
      deletedAt: null,
      organization: this.organization()?.uid,
      isGuest: false,
    };
    await this.firestore.addDoc(FirestoreCollections.User, createUserData);
    this.isCreatingUser = false;
  }

  async deleteUser(userId: string): Promise<void> {
    this.alertService.alert({
      title: 'Deletar usuário',
      message:
        'Tem certeza que deseja deletar este usuário? \nVocê só poderá reativá-lo em 14 dias. \nFale conosco caso precise de um plano com mais colaboradores.',
      confirmButtonText: 'Deletar',
      confirmCallback: () => {
        this.firestore.updateDoc(FirestoreCollections.User, userId, {
          deletedAt: new Date(),
        });
      },
    });
  }

  async initializeUser(userId: string) {
    const user = (await this.getUserById(userId)) as IBluUser;
    if (!user) throw new Error(`User not found ${userId}.`);
    user.type = 'user';
    this.number = user.numbers[0];
    this.user.set(user);
    await this.initializeOrg(user.organization);
  }

  initializeEmptyUser() {
    this.user.set(null);
    this.organization.set(null);
  }

  async initializeAdmin(adminId: string): Promise<void> {
    // assign service admin
    const adminDocRef = this.firestore.doc<IUser>(
      FirestoreCollections.Admin,
      adminId,
    );
    const unsubscribe = onSnapshot(adminDocRef, async (adminDocSnap) => {
      const adminData = adminDocSnap.data();
      if (!adminData) return;
      const user = {
        ...adminData,
        type: adminData?.role || 'admin',
      } as IBluUser;
      await this.initializeAdminOrg(adminData.uid);
      this.user.set(user);
      unsubscribe();
    });
  }

  private async initializeAdminOrg(adminId: string) {
    // find org with admin listed
    const orgsSnap = await this.firestore.getDocs(
      FirestoreCollections.Organization,
      where('admins', 'array-contains', adminId),
    );
    if (!orgsSnap.size) {
      // this.authService.signOut();
      throw new Error(`Admin org not found ${adminId}`);
    }
    const org = orgsSnap.docs[0];
    onSnapshot(org.ref, (orgDocSnap) => {
      const orgData = orgDocSnap.data() as IOrganization;
      this.organization.set(orgData);
    });
  }

  subscriptionIsActive(): boolean {
    const status = this.organization()?.subscription?.status;
    if (!status) return false;
    const statusArray = ['active', 'trialing'];
    return statusArray.includes(status);
  }

  getChannelLimit(): number {
    let limit: number;
    const subs = this.organization()?.subscription;
    switch (subs?.plan) {
      case 'individual':
        limit = 1;
        break;
      case 'basico':
        limit = 1;
        break;
      case 'plus':
        limit = 1;
        break;
      case 'pro':
        limit = 1;
        break;
      default:
        limit = 0;
    }
    const addonCount =
      subs?.addons?.filter((addon) => addon === '1_channel').length || 0;
    return limit + addonCount;
  }
  getOperatorLimit(): number {
    let limit: number;
    const subs = this.organization()?.subscription;
    switch (subs?.plan) {
      case 'individual':
        limit = 2;
        break;
      case 'basico':
        limit = 2;
        break;
      case 'plus':
        limit = 2;
        break;
      case 'pro':
        limit = 2;
        break;
      default:
        limit = 0;
    }
    const addonCount =
      subs?.addons?.filter((addon) => addon === '1_operator').length || 0;
    return limit + addonCount;
  }

  getWarmupLimit(): number {
    let limit: number;
    const subs = this.organization()?.subscription;
    switch (subs?.plan) {
      case 'individual':
        limit = 0;
        break;
      case 'basico':
        limit = 1;
        break;
      case 'plus':
        limit = 1;
        break;
      case 'pro':
        limit = 1;
        break;
      default:
        limit = 0;
    }
    const addonCount =
      subs?.addons?.filter((addon) => addon === '1_warmup').length || 0;
    return limit + addonCount;
  }

  onboardingStepVisible(step: keyof typeof OnboardingStepKey): boolean {
    const onboardingStep =
      this.organization()?.onboarding?.[OnboardingStepKey[step]];
    // legacy accounts or new accounts where the step is completed or dismissed
    if (
      !onboardingStep ||
      onboardingStep.completedAt ||
      onboardingStep.dismissedAt
    ) {
      return true;
    }
    // new accounts where the step hasn't been completed nor dismissed
    return false;
  }
}
