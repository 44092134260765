import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import {
  FirestoreCollections,
  IFlow,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { UserService } from './user.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { FlowService } from './flow.service';
import { ChannelsService } from './channels.service';
import { OnboardingService } from './onboarding.service';

@Injectable({
  providedIn: 'root',
})
export class FlowListService {
  flows: IFlow[] = [];

  constructor(
    private firestore: SharedFirestoreService,
    private userService: UserService,
    private flowService: FlowService,
    private channelsService: ChannelsService,
    private onboardingService: OnboardingService,
  ) {}

  async getFlows() {
    this.flows = [];
    const orgId = this.userService.organization()?.uid;
    if (!orgId) return;
    const flowsQuerySnap = await this.firestore.getDocs(
      FirestoreCollections.Flow,
      where('organization', '==', orgId),
    );
    for (const queryDocSnap of flowsQuerySnap.docs) {
      this.flows.push(queryDocSnap.data() as IFlow);
    }
  }

  async getFlow(id: string) {
    if (this.flows.length) {
      return this.flows.find((flow) => flow.uid === id);
    }
    return this.firestore.getDoc<IFlow>(FirestoreCollections.Flow, id);
  }

  async deleteFlow(flowId: string) {
    const promises = [
      this.firestore.deleteDocAndSubCollection(
        FirestoreCollections.Flow,
        FirestoreCollections.Node,
        flowId,
      ),
    ];
    const channels = await this.channelsService.channels.filter(
      (channel) => channel.activeFlow === flowId,
    );
    for (const channel of channels) {
      if (channel.uid) {
        promises.push(
          this.firestore.updateDoc(FirestoreCollections.Channel, channel.uid, {
            activeFlow: null,
          }),
        );
        channel.activeFlow = null;
      }
    }
    const index = this.flows.findIndex((flow) => flow.uid === flowId);
    if (index > -1) this.flows.splice(index, 1);
  }

  async updateFlow(id: string, flow: Partial<IFlow>) {
    await this.firestore.updateDoc(FirestoreCollections.Flow, id, flow);
    const index = this.flows.findIndex((flow) => flow.uid === id);
    if (index > -1) this.flows[index] = Object.assign(this.flows[index], flow);
  }

  async createFlow(flow: IFlow) {
    const newFlowDocRef = await this.firestore.addDoc(
      FirestoreCollections.Flow,
      { ...flow, organization: this.userService.organization()?.uid },
    );
    this.flows.push({ ...flow, uid: newFlowDocRef.id });
    const newNode = await this.flowService.addNode(newFlowDocRef.id, {
      coords: {
        x: 4,
        y: 4,
      },
      label: 'Início',
      text: 'Nova opção',
      responseOptions: [
        {
          label: 'Nova resposta',
          index: 0,
          actionNode: null,
        },
      ],
    });
    await this.firestore.updateDoc(
      FirestoreCollections.Flow,
      newFlowDocRef.id,
      {
        startingNode: newNode?.id,
      },
    );
    this.onboardingService.completeStep(OnboardingStepKey.createdFlow);
  }
}
