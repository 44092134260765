@if (channel) {
  <shared-modal [mobile]="sharedService.screenSize !== 'l'">
    <!-- header -->
    <h2 slot="header">Editar canal</h2>

    <!-- body -->
    <div class="flex flex-col gap-3">
      <kor-input
        label="Nome"
        [value]="channel.name"
        (input)="setName($event.target)"
      ></kor-input>
      <div class="grid gap-[--gap-m] [grid-template-columns:80px_80px_1fr]">
        <kor-input
          no-clear
          label="País"
          maxlength="2"
          [value]="number.countryCode"
          (input)="setNumber('countryCode', $event, 2)"
        ></kor-input>
        <kor-input
          no-clear
          label="DDD"
          maxlength="2"
          [value]="number.areaCode"
          (input)="setNumber('areaCode', $event, 2)"
        ></kor-input>
        <kor-input
          no-clear
          label="Número"
          maxlength="8"
          [value]="number.number"
          (input)="setNumber('number', $event, 8)"
        ></kor-input>
      </div>
    </div>

    <hr />

    <div class="flex flex-col gap-3">
      <h2>Atendentes</h2>
      <div class="flex flex-col gap-2">
        @if (operatorListService.operators.length > 0) {
          @for (operator of operatorListService.operators; track operator) {
            <kor-checkbox
              [label]="operator.name"
              [active]="channel.operators.includes(operator.uid!)"
              (click)="
                $event.stopImmediatePropagation();
                addOperatorToChannel(operator.uid)
              "
            >
            </kor-checkbox>
          }
        } @else {
          <span>
            Nenhum atendente registrado.
            <a
              href="https://blu.direct/admin/chat/operators"
              class="font-bold text-accent-600"
              >Registre um atendente</a
            >.</span
          >
        }
      </div>
    </div>

    <div class="flex flex-col gap-3">
      <h2>Outros</h2>
      <div class="flex items-center gap-2">
        <kor-checkbox
          label="Aquecer este chip automaticamente"
          [active]="channel.startedWarmingUpAt"
          (click)="toggleWarmUp($event)"
          class="flex-1"
          [disabled]="!channel.startedWarmingUpAt && !canWarmup()"
        >
        </kor-checkbox>
      </div>
    </div>

    <!-- footer -->
    @if (channel.uid) {
      <kor-button
        class="mr-auto"
        slot="footer"
        color="tertiary"
        label="Deletar"
        (click)="
          delete(); analyticsService.logEvent('admin_delete_channel_click')
        "
      ></kor-button>
    }
    <kor-button
      slot="footer"
      color="secondary"
      label="Cancelar"
      (click)="
        sharedService.closeModal(route);
        analyticsService.logEvent('admin_close_channel_modal')
      "
    >
    </kor-button>
    <kor-button
      slot="footer"
      label="Confirmar"
      [disabled]="!channel.name || !channel.numbers.length"
      (click)="save(); analyticsService.logEvent('admin_save_channel_click')"
    >
    </kor-button>
  </shared-modal>
}
