<aside class="shared-side-bar">
  <h2 class="flex items-center justify-between">
    Disparos
    @if (sharedService.screenSize === 's') {
      <kor-icon
        icon="close"
        (click)="
          close.emit(); analyticsService.logEvent('admin_nav_close_click')
        "
      ></kor-icon>
    }
  </h2>
  <div>
    <kor-menu-item
      label="Estatísticas"
      icon="insights"
      routerLink="/admin/send/stats"
      [active]="router.url.includes('/admin/send/stats')"
      [toggle]="false"
      (click)="analyticsService.logEvent('admin_nav_send_stats_tab_click')"
    ></kor-menu-item>
    <kor-menu-item
      label="Chaves de acesso"
      icon="lock_open"
      routerLink="/admin/send/users"
      [active]="router.url.includes('/admin/send/users')"
      [toggle]="false"
      (click)="analyticsService.logEvent('admin_nav_send_users_tab_click')"
    ></kor-menu-item>
    <kor-menu-item
      label="Templates"
      icon="wysiwyg"
      routerLink="/admin/send/templates"
      [active]="router.url.includes('/admin/send/templates')"
      [toggle]="false"
      (click)="analyticsService.logEvent('admin_nav_send_templates_tab_click')"
    ></kor-menu-item>
  </div>

  <hr />

  <h2 class="flex items-center justify-between">Chatbot</h2>
  <div>
    <kor-menu-item
      icon="account_tree"
      label="Chatbots"
      routerLink="/admin/chat/flows"
      [active]="router.url.includes('/admin/chat/flows')"
      (click)="analyticsService.logEvent('admin_nav_flows_tab_click')"
      [toggle]="false"
    ></kor-menu-item>
    <kor-menu-item
      icon="phonelink_ring"
      label="Canais"
      routerLink="/admin/chat/channels"
      [active]="router.url.includes('/admin/chat/channels')"
      [toggle]="false"
      (click)="analyticsService.logEvent('admin_nav_channels_tab_click')"
    ></kor-menu-item>
    <kor-menu-item
      icon="support_agent"
      label="Atendentes"
      routerLink="/admin/chat/operators"
      [active]="router.url.includes('/admin/chat/operators')"
      [toggle]="false"
      (click)="analyticsService.logEvent('admin_nav_operators_tab_click')"
    ></kor-menu-item>
  </div>
  <!-- <kor-menu-item
      icon="label"
      label="Categorias"
      routerLink="/admin/chat/categories"
      [active]="router.url.includes('/admin/chat/categories')"
      [toggle]="false"
    ></kor-menu-item> -->

  <!-- functions -->
  <hr class="mb-4 mt-auto" />
  <a
    href="https://home.blu.direct/blog/visao-geral-painel-administrador"
    target="_blank"
    class="no-underline"
  >
    <kor-menu-item icon="help" label="Ajuda" [toggle]="false"></kor-menu-item>
  </a>
</aside>
