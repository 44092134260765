import { Injectable } from '@angular/core';

type AlertOptions = {
  title?: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  shouldShow = false;

  title? = '';
  message = '';
  confirmButtonText? = '';
  cancelButtonText? = '';
  confirmCallback?: () => void;
  cancelCallback?: () => void;

  constructor() {}

  alert(options: AlertOptions) {
    this.title = options.title;
    this.message = options.message;
    this.confirmButtonText = options.confirmButtonText;
    this.cancelButtonText = options.cancelButtonText || '';
    this.confirmCallback = () => this.wrapper(options.confirmCallback);
    this.cancelCallback = () => this.wrapper(options.cancelCallback);
    this.shouldShow = true;
  }

  wrapper(callback?: () => void) {
    this.shouldShow = false;
    if (callback) callback();
  }
}
