<div class="card">
  <!-- templates -->
  <h2>
    <kor-icon icon="wysiwyg"></kor-icon>Templates ({{ templates.length }})
    <kor-button
      color="tertiary"
      icon="add"
      [label]="sharedService.screenSize !== 's' ? 'Adicionar' : null"
      [routerLink]="[{ outlets: { modal: ['add-template', ''] } }]"
      (click)="analyticsService.logEvent('admin_add_template_button_click')"
    ></kor-button>
  </h2>
  <kor-table>
    <kor-table-row slot="header">
      <kor-table-cell head> Nome </kor-table-cell>
      <kor-table-cell head alignment="right">Clientes (30 dias)</kor-table-cell>
    </kor-table-row>
    @for (template of templates; track template.uid) {
      <kor-table-row
        [routerLink]="[{ outlets: { modal: ['add-template', template.uid] } }]"
        (click)="analyticsService.logEvent('admin_template_row_click')"
      >
        <kor-table-cell> {{ template.name }}</kor-table-cell>
        <kor-table-cell alignment="right">
          @if (template.guestsCount) {
            <kor-tag
              class="button"
              icon="group"
              [label]="template.guestsCount"
              (click)="$event.stopPropagation()"
            ></kor-tag>
          }
        </kor-table-cell>
      </kor-table-row>
    }
  </kor-table>

  <section class="footer">
    @if (!loadingGuestRecipientsCount) {
      <!-- count -->
      <p>
        {{ guestRecipientsCount || 0 }} leads gerados desde
        {{
          (
            userService.organization()?.lastGuestRecipientsExportDate ||
            userService.organization()?.createdAt
          )?.toDate() | date: 'MMM d YYYY'
        }}
      </p>
    } @else {
      <kor-spinner></kor-spinner>
      <p>Carregando leads gerados</p>
    }

    <!-- csv -->
    @if (!loadingGuestRecipientsCsv) {
      <kor-button
        label="Exportar leads"
        [disabled]="!guestRecipientsCount || guestRecipientsCount === 0"
        (click)="
          getGuestRecipientsCsv();
          analyticsService.logEvent('admin_export_leads_button_click')
        "
      ></kor-button>
    } @else {
      <kor-spinner></kor-spinner>
    }
  </section>

  <router-outlet name="modal"></router-outlet>
</div>
