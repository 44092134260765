import { Injectable } from '@angular/core';

type ToastOptions = {
  title: string;
  message: ToastMessage;
  icon: string;
  iconColor?: string;
};

export enum ToastMessage {
  DuplicateContacts = 'Sua seleção de contatos contém alguns números duplicados. Modifique sua planilha para evitar que pessoas recebam mensagem mais de uma vez.',
  PreviewCampaignSuccess = 'Seu teste foi concluído com sucesso. Cheque seu WhatsApp para ver o conteúdo da mensagem, e ajuste ou prossiga como desejar.',
  NewSimCardWarning = 'Disparos com chips recém cadastrados frequentemente resultam em banimento.\n Recomendamos a realização do aquecimento do chip antes do disparo.',
  ChannelNotConnected = 'Peça ao seu coordenador para conectar o aplicativo no painel do administrador para poder receber e enviar mensagens.',
  ConnectionLost = 'Certifique de que seu computador está conectado com a internet, e atualize a página.',
  ConnectedFromAnotherWindow = 'O Blü foi conectado em uma outra janela. Atualize a página para conectar novamente.',
  SubscriptionExpired = 'Sua assinatura expirou. Entre em contato com o suporte para renovar.',
  NoChannelsFound = 'Nenhum canal encontrado. Peça ao seu coordenador para adicionar um canal para você.',
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  shouldShow = false;
  title = '';
  message = '';
  icon = '';
  iconColor? = '';

  createToast(options: ToastOptions) {
    this.title = options.title;
    this.message = options.message;
    this.icon = options.icon;
    this.iconColor = options.iconColor;
    this.show();
  }

  show() {
    this.shouldShow = true;
  }

  hide() {
    this.shouldShow = false;
  }
}
